import React from "react";
import "../../css/info.css";
import grippin_ko from "../../image/grippin_ko.png";
import groupware_ko from "../../image/groupware_ko.png"
import groupware_en from "../../image/groupware_en.png"
import SideBar from "../../main/SideBar";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

function GroupWare(){
    const {t} = useTranslation();

    return(
      <>
      <SideBar />
          <br/>
          <div className='commandArea'>
             <div className='detailTitle'>{t('GROUPWARE_TITLE')}</div>
              <div className='detailData'>{t('GROUPWARE_DATA')}</div>
              {i18next.language==='kr'?<img src={groupware_ko} className={'detailImage'}/> :
                  <img src={groupware_en} className={'detailImage'}/>}

              <div className='datailCategory'>{t('DETAIL_CATEGORY_FUNCTION')}</div>
              <div className={'detailSubTitle'}>{t('GROUPWARE_FUNC_A')}</div>
              <div className={'detailData'}>{t('GROUPWARE_FUNC_A001')}</div>

              <div className={'detailSubTitle'}>{t('GROUPWARE_FUNC_B')}</div>
              <div className={'detailData'}>{t('GROUPWARE_FUNC_B001')}</div>

              <div className={'detailSubTitle'}>{t('GROUPWARE_FUNC_C')}</div>
              <div className={'detailData'}>{t('GROUPWARE_FUNC_C001')}</div>

              <div className={'detailSubTitle'}>{t('GROUPWARE_FUNC_D')}</div>
              <div className={'detailData'}>
                  <div>{t('GROUPWARE_FUNC_D001')}</div>
                  <div>{t('GROUPWARE_FUNC_D002')}</div>
                  <div>{t('GROUPWARE_FUNC_D003')}</div>
              </div>

              <br />
              <div className='datailCategory'>{t('DETAIL_CATEGORY_FETURE')}</div>
              <div className={'detailSubTitle'}>{t('GROUPWARE_FETURE_A')}</div>
              <div className={'detailData'}>{t('GROUPWARE_FETURE_A001')}</div>

          </div>
      </>
    );
}

export default GroupWare;
