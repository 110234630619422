import { CssBaseline} from "@mui/material";
import Header from "./main/Header";
import MainContainer from './main/container/MainContainer';

import "./css/main.css";

const App = () => {
   const chartWidth=window.innerWeight+'px';
   const minHeight=window.innerHeight+'px';

  return (
    <>
    {/* MUI 통합 css */}
    <CssBaseline/>
        <Header style={{width:chartWidth, minHeight:minHeight}}/>
        <MainContainer />
      <footer>Copyright © Keystone security CO.,LTD. ALL RIGHTS RESERVED.</footer>
    </>
  )
}

export default App;
