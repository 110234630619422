import React from "react";
import '../css/info.css'
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SideBar from "../main/SideBar";
import Faq from "./Faq";

function Support(){
    const navigate = useNavigate();
    const chartHeight=String(Number(window.innerHeight)-124)+'px';
    const {t} = useTranslation();

    return(
        <>
            <SideBar />
            <br/>
            <div className='commandArea' style={{minHeight:chartHeight}}>
                <div className='infoTitle'>Contact Us</div>
                <div style={{fontSize:'15.5px', fontWeight:'bold'}}>Republic of Korea</div>
                <div className='infoData'>
                    {t('CONTACT_ADDRESS')}<br/>
                    07281<br/>
                    Tel : +82 2-706-1003<br/>
                    E-Mail : tj@keystonesec.com<br/>
                </div>


                <div style={{fontSize:'15.5px', fontWeight:'bold'}}>United State of America</div>
                <div className='infoData'>
                    1755 leaning pine Dr. Diamond bar, CA<br/>
                    91765<br/>
                    Tel : +1 909-634-7858<br/>
                    E-Mail : diskfile@keystonesec.com<br/>
                </div>

                <br/>
                <div className='infoTitle' style={{cursor:'pointer'}} onClick={()=>navigate('/support/faq')}>FAQ</div>
                {/*<Faq />*/}
            </div>
        </>
    );
}

export default Support;
