import React, {useEffect, useState} from 'react';
import {Button, Grid
} from '@mui/material';
import { useNavigate  } from 'react-router-dom';
import LanguageIcon from '@mui/icons-material/Language';
import i18next from 'i18next';

function Header(){
    const navigate = useNavigate();

    const onToggle=()=>{
        i18next.language ==='en'?i18next.changeLanguage('kr'):i18next.changeLanguage('en');
        localStorage.setItem('lang',  i18next.language);
    }

    return (
        <div style={{backgroundColor:'rgb(29,41,116)', height:'70px'}}>
            <Grid width={"55%"} sx={{float:'left', height:'70px' ,display:'flex'}}>
                <div onClick={()=> navigate('/')} className='headerLogo'>Keystone security</div>
            </Grid>
            <Grid textAlign={"right"} width={"45%"} sx={{float:'right',height:'70px', paddingRight:'25px', lineHeight:'70px'}}>
                <Button sx={{color:"white", fontSize:"14px"}} onClick={()=>navigate("/products")}> menu </Button>
                <Button sx={{color:"white"}} onClick={()=>onToggle()}><LanguageIcon /></Button>
            </Grid>
        </div>

    );
}

export default Header;
