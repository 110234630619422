import React from "react";
import {useTranslation} from "react-i18next";

const AmoaDataList = () =>{
    const {t} = useTranslation();
    const langData=[
        {   id:1,
            question:t('AMOA_FAQ_Q1'),
            answer:t('AMOA_FAQ_A1'),
            open:false
        },
        {   id:2,
            question:t('AMOA_FAQ_Q2'),
            answer:t('AMOA_FAQ_A2'),
            open:false
        },
        {   id:3,
            question:t('AMOA_FAQ_Q3'),
            answer:t('AMOA_FAQ_A3'),
            open:false
        },
        {   id:4,
            question:t('AMOA_FAQ_Q4'),
            answer:t('AMOA_FAQ_A4'),
            open:false
        },
        {   id:5,
            question:t('AMOA_FAQ_Q5'),
            answer:t('AMOA_FAQ_A5'),
            open:false
        },
        {   id:6,
            question:t('AMOA_FAQ_Q6'),
            answer:t('AMOA_FAQ_A6'),
            open:false
        },
        {   id:7,
            question:t('AMOA_FAQ_Q7'),
            answer:t('AMOA_FAQ_A7'),
            open:false
        },
        {   id:8,
            question:t('AMOA_FAQ_Q8'),
            answer:t('AMOA_FAQ_A8'),
            open:false
        },
        {   id:9,
            question:t('AMOA_FAQ_Q9'),
            answer:t('AMOA_FAQ_A9'),
            open:false
        },
        {   id:10,
            question:t('AMOA_FAQ_Q10'),
            answer:t('AMOA_FAQ_A10'),
            open:false
        },
        {   id:11,
            question:t('AMOA_FAQ_Q11'),
            answer:t('AMOA_FAQ_A11'),
            open:false
        },
        {   id:12,
            question:t('AMOA_FAQ_Q12'),
            answer:t('AMOA_FAQ_A12'),
            open:false
        },
        {   id:13,
            question:t('AMOA_FAQ_Q13'),
            answer:t('AMOA_FAQ_A13'),
            open:false
        },
        {   id:14,
            question:t('AMOA_FAQ_Q14'),
            answer:t('AMOA_FAQ_A14'),
            open:false
        },
        {   id:15,
            question:t('AMOA_FAQ_Q15'),
            answer:t('AMOA_FAQ_A15'),
            open:false
        }
    ]    
    
    return langData;
}

export default AmoaDataList;
