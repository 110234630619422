import React from "react";
import "../../css/info.css";
import grippin_ko from "../../image/grippin_ko.png";
import SideBar from "../../main/SideBar";
import {useTranslation} from "react-i18next";
import i18next from "i18next";


function Grippin(){
    const {t} = useTranslation();

    return(
        <>
            <SideBar/>
            <br/>
            <div className='commandArea'>
                <div className='detailTitle'>{t('GRIPPIN_TITLE')}</div>
                <div className='detailData'>{t('GRIPPIN_DATA')}</div>
                {i18next.language === 'kr' ? <img src={grippin_ko} className={'detailImage'}/> :
                    <img src={grippin_ko} className={'detailImage'}/>}

                <div className='datailCategory'>{t('DETAIL_CATEGORY_FUNCTION')}</div>
                <div className={'detailSubTitle'}>{t('GRIPPIN_FUNC_A')}</div>
                <div className={'detailData'}>
                    <div>{t('GRIPPIN_FUNC_A001')}</div>
                    <div>{t('GRIPPIN_FUNC_A002')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('GRIPPIN_FUNC_B')}</div>
                <div className={'detailData'}>
                    <div>{t('GRIPPIN_FUNC_B001')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('GRIPPIN_FUNC_C')}</div>
                <div className={'detailData'}>
                    <div>{t('GRIPPIN_FUNC_C001')}</div>
                    <div>{t('GRIPPIN_FUNC_C002')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('GRIPPIN_FUNC_D')}</div>
                <div className={'detailData'}>
                    <div>{t('GRIPPIN_FUNC_D001')}</div>
                    <div>{t('GRIPPIN_FUNC_D002')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('GRIPPIN_FUNC_E')}</div>
                <div className={'detailData'}>
                    <div>{t('GRIPPIN_FUNC_E001')}</div>
                    <div>{t('GRIPPIN_FUNC_E002')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('GRIPPIN_FUNC_F')}</div>
                <div className={'detailData'}>
                    <div>{t('GRIPPIN_FUNC_F001')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('GRIPPIN_FUNC_G')}</div>
                <div className={'detailData'}>
                    <div>{t('GRIPPIN_FUNC_G001')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('GRIPPIN_FUNC_H')}</div>
                <div className={'detailData'}>
                    <div>{t('GRIPPIN_FUNC_H001')}</div>
                </div>

                <br/>
                <div className='datailCategory'>{t('DETAIL_CATEGORY_FETURE')}</div>
                <div className={'detailSubTitle'}>{t('GRIPPIN_FETURE_A')}</div>
                <div className={'detailData'}>{t('GRIPPIN_FETURE_A001')}</div>

                <div className={'detailSubTitle'}>{t('GRIPPIN_FETURE_B')}</div>
                <div className={'detailData'}>
                    <div>{t('GRIPPIN_FETURE_B001')}</div>
                    <div>{t('GRIPPIN_FETURE_B002')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('GRIPPIN_FETURE_B')}</div>
                <div className={'detailData'}>
                    <div>{t('GRIPPIN_FETURE_B001')}</div>
                    <div>{t('GRIPPIN_FETURE_B002')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('GRIPPIN_FETURE_C')}</div>
                <div className={'detailData'}>
                    <div>{t('GRIPPIN_FETURE_C001')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('GRIPPIN_FETURE_D')}</div>
                <div className={'detailData'}>
                    <div>{t('GRIPPIN_FETURE_D001')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('GRIPPIN_FETURE_E')}</div>
                <div className={'detailData'}>
                    <div>{t('GRIPPIN_FETURE_E001')}</div>
                    <div>{t('GRIPPIN_FETURE_E002')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('GRIPPIN_FETURE_F')}</div>
                <div className={'detailData'}>
                    <div>{t('GRIPPIN_FETURE_F001')}</div>
                    <div>{t('GRIPPIN_FETURE_F002')}</div>
                </div>
            </div>
        </>
    );
}

export default Grippin;
