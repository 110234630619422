import React from "react";
import "../../css/info.css";
import SideBar from "../../main/SideBar";
import encryption_ko from "../../image/damo_ko.png";
import encryption_en from "../../image/damo_en.png";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

function Encryption(){
    const {t} = useTranslation();

    return(
        <>
            <SideBar/>
            <br/>
            <div className='commandArea'>
                <div className='detailTitle'>{t('ENCRYPTION_TITLE')}</div>
                <div className='detailData'>{t('ENCRYPTION_DATA')}</div>
                {i18next.language==='kr'? <img src={encryption_ko} className={'detailImage'}/> :
                    <img src={encryption_en} className={'detailImage'}/>}

                <div className='datailCategory'>{t('DETAIL_CATEGORY_FUNCTION')}</div>
                <div className='detailSubTitle'>{t('ENCRYPTION_FUNC_A')}</div>
                <div className='detailData'>
                    <div>{t('ENCRYPTION_FUNC_A001')}</div>
                    <div>{t('ENCRYPTION_FUNC_A002')}</div>
                </div>

                <div className='detailSubTitle'>{t('ENCRYPTION_FUNC_B')}</div>
                <div className='detailData'>
                    <div>{t('ENCRYPTION_FUNC_B001')}</div>
                    <div>{t('ENCRYPTION_FUNC_B002')}</div>
                    <div>{t('ENCRYPTION_FUNC_B003')}</div>
                </div>

                <div className='detailSubTitle'>{t('ENCRYPTION_FUNC_C')}</div>
                <div className='detailData'>
                    <div>{t('ENCRYPTION_FUNC_C001')}</div>
                    <div>{t('ENCRYPTION_FUNC_C002')}</div>
                    <div>{t('ENCRYPTION_FUNC_C003')}</div>
                </div>

                <br />
                <div className='datailCategory'>{t('DETAIL_CATEGORY_FETURE')}</div>
                <div className='detailSubTitle'>{t('ENCRYPTION_FETURE_A')}</div>
                <div className='detailData'>{t('ENCRYPTION_FETURE_A001')}</div>

                 <div className='detailSubTitle'>{t('ENCRYPTION_FETURE_B')}</div>
                <div className='detailData'>{t('ENCRYPTION_FETURE_B001')}</div>


                <div className='detailSubTitle'>{t('ENCRYPTION_FETURE_C')}</div>
                <div className='detailData'>{t('ENCRYPTION_FETURE_C001')}</div>

                <div className='detailSubTitle'>{t('ENCRYPTION_FETURE_D')}</div>
                <div className='detailData'>{t('ENCRYPTION_FETURE_D001')}</div>

           </div>
        </>
    );
}

export default Encryption;
