import React from "react";
import "../../css/info.css";
import safem_kr from "../../image/safem_kr.jpg";
import safem_en from "../../image/safem_en.png";
import SideBar from "../../main/SideBar";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

function PrivacyScan(){
    const {t} = useTranslation();

    return(
        <>
            <SideBar/>
            <br/>
            <div className='commandArea'>
                <div className='detailTitle'>{t('SAFEM_TITLE')}</div>
                <div className='detailData'>{t('SAFEM_DATA')}</div>
                {i18next.language==='kr'?<img src={safem_kr} className={'safeMImage'}/> :
                    <img src={safem_en} className={'safeMImage'}/>}

                <div className='datailCategory'>{t('DETAIL_CATEGORY_FUNCTION')}</div>
                <div className={'detailSubTitle'}>{t('SAFEM_FUNC_A')}</div>
                <div className={'detailData'}>
                    <div>{t('SAFEM_FUNC_A001')}</div>
                    <div>{t('SAFEM_FUNC_A002')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('SAFEM_FUNC_B')}</div>
                <div className={'detailData'}>
                    <div>{t('SAFEM_FUNC_B001')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('SAFEM_FUNC_C')}</div>
                <div className={'detailData'}>
                    <div>{t('SAFEM_FUNC_C001')}</div>
                    <div>{t('SAFEM_FUNC_C002')}</div>
                    <div>{t('SAFEM_FUNC_C003')}</div>
                    <div>{t('SAFEM_FUNC_C004')}</div>
                </div>
            </div>
        </>
    );
}

export default PrivacyScan;
