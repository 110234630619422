import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import translationEn from './translation.en.json';
import translationKo from './translation.ko.json';


const resource = {
    en: {translation: translationEn},
    kr: {translation: translationKo}
}

const init = (lang) => {
    i18n.use(initReactI18next).init({
        resources: resource,
        fallbackLng: 'kr',
        debug: true,
        interpolation: {
            escapeValue: true,
            prefix: '{',
            suffix: '}',
        },
        lng: lang,
    })
}

export default init;