import React  from "react";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {Divider} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import "../css/info.css"

function SideBar() {
    const navigate=useNavigate();
    const {t} = useTranslation();

    const data=[
        {
            name:t('SIDE_GROUPWARE'),   //그룹웨어
            url:'groupware'
        },
        {
            name:t('SIDE_PRIVACYSCAN'), //개인정보 검출
            url:'privacyscan'
        },
        {
            name:t('SIDE_ENCRYPTION'),  //데이터 암호화
            url:'encryption'
        },
        {
            name:t('SIDE_KMS'),  //KMS
            url:'kms'
        },
        {
            name:t('SIDE_AUTHENTICATION'),  //통합 사용자 인증(mSAFER)
            url:'authentication'
        },
        {
            name:t('SIDE_VFINDER'), //모바일 앱 취약점 점검(vfinder)
            url:'vfinder'
        },
        {
            name:t('SIDE_SAFEM'),    //safeM
            url:'safem'
        },
        // {
        //     name:t('SIDE_GRIPPIN'),    //오픈 소스 관리 시스템(cybellum)
        //     url:'grippin'
        // },
        {
            name:t('SIDE_CYBELLUM'),    //오픈 소스 관리 시스템(cybellum)
            url:'cybellum'
        },
        {
            name:t('SIDE_SPARROW'),    //통합 보안 관제 시스템(sparrow)
            url:'sparrow'
        },
        {
            name:t('SIDE_FIREWALL'),    //방화벽(NGF)
            url:'firewall'
        },
        {
            name:t('SIDE_SIEM'),    //통합 보안 관제 시스템(SIEM)
            url:'siem'
        }
    ]

    return (
        <>
            <Box role="presentation" >
                <div className={'sideCss'}>
                    <List>
                        {data.map((data) => (

                            <ListItem button key={data.name}>
                                <ListItemText primary={
                                    <div style={{fontSize:'15px',fontWeight:'bold', width:'150px'}} onClick={()=> {
                                        navigate('/products/'+data.url)
                                    }}>{data.name}</div>
                                }/>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        <ListItem button key={'contact'}>
                            <ListItemText primary={
                                <div style={{fontSize:'15px', width:'150px', fontWeight:'bold'}} onClick={()=>{
                                    navigate('/support')
                                }}>{t('SIDE_CONTACT')}</div> }/>
                        </ListItem>

                        {/* <ListItem button key={'faq'}>
                            <ListItemText primary={
                                <div style={{fontSize:'15px', width:'150px', fontWeight:'bold'}} onClick={()=> {
                                    navigate('/support/faq')}}>{t('SIDE_FAQ')}</div> }/>
                        </ListItem> */}
                    </List>
                </div>
            </Box>
        </>
    );
}


export default SideBar;
