import React from "react";
import "../../css/info.css";
import SideBar from "../../main/SideBar";
import vfinder_kr from "../../image/vFinder.png"
import vfinder_en from "../../image/vfinder_en.png";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

function GroupWare(){
    const {t} = useTranslation();

    return(
        <>
            <SideBar />
            <br/>
            <div className='commandArea'>
                <div className='detailTitle'>{t('VFINDER_TITLE')}</div>
                <div className='detailData'>{t('VFINDER_DATA')}</div>
                {i18next.language==='kr'?<img src={vfinder_kr} className={'detailImage'}/> :
                    <img src={vfinder_en} className={'detailImage'}/>}

                <div className='datailCategory'>{t('DETAIL_CATEGORY_FUNCTION')}</div>
                <div className='detailSubTitle'>{t('VFINDER_FUNC_A')}</div>
                <div className='detailData'>
                    <div>{t('VFINDER_FUNC_A001')}</div>
                    <div>{t('VFINDER_FUNC_A002')}</div>
                    <div>{t('VFINDER_FUNC_A003')}</div>
                    <div>{t('VFINDER_FUNC_A004')}</div>
                    <div>{t('VFINDER_FUNC_A005')}</div>
                    <div>{t('VFINDER_FUNC_A006')}</div>
                    <div>{t('VFINDER_FUNC_A007')}</div>
                </div>

                <div className='detailSubTitle'>{t('VFINDER_FUNC_B')}</div>
                <div className='detailData'>
                    <div>{t('VFINDER_FUNC_B001')}</div>
                    <div>{t('VFINDER_FUNC_B002')}</div>
                    <div>{t('VFINDER_FUNC_B003')}</div>
                    <div>{t('VFINDER_FUNC_B004')}</div>
                </div>

                <div className='detailSubTitle'>{t('VFINDER_FUNC_C')}</div>
                <div className='detailData'>
                    <div>{t('VFINDER_FUNC_C001')}</div>
                    <div>{t('VFINDER_FUNC_C002')}</div>
                </div>

                <br/>
                <div className='datailCategory'>{t('DETAIL_CATEGORY_FETURE')}</div>
                <div className='detailSubTitle'>{t('VFINDER_FETURE_A')}</div>
                <div className='detailData'>{t('VFINDER_FETURE_A001')}</div>

                <div className='detailSubTitle'>{t('VFINDER_FETURE_B')}</div>
                <div className='detailData'>{t('VFINDER_FETURE_B001')}</div>

                <div className='detailSubTitle'>{t('VFINDER_FETURE_C')}</div>
                <div className='detailData'>{t('VFINDER_FETURE_C001')}</div>

                <div className='detailSubTitle'>{t('VFINDER_FETURE_D')}</div>
                <div className='detailData'>{t('VFINDER_FETURE_D001')}</div>
            </div>
        </>
    );
}

export default GroupWare;
