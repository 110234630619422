import React from "react";
import { Route, Routes } from "react-router-dom";
import MainPage from "../MainPage";
import Products from "../../Products/Products";
import Support from "../../Support/Support";

import GroupWare from "../../Products/ProductData/GroupWare";
import PrivacyScan from "../../Products/ProductData/PrivacyScan";
import Encryption from "../../Products/ProductData/Encryption";
import Authentication from "../../Products/ProductData/Authentication";
import VFinder from "../../Products/ProductData/VFinder";
import Firewall from "../../Products/ProductData/Firewall";
import Siem from "../../Products/ProductData/Siem";
import KMS from "../../Products/ProductData/KMS";
import SafeM from "../../Products/ProductData/SafeM";
import Sparrow from "../../Products/ProductData/Sparrow";
import Cybellum from "../../Products/ProductData/Cybellum";
import Grippin from "../../Products/ProductData/Grippin";

import Faq from "../../Support/Faq";
import FaqDetail from "../../Support/FaqDetail";

import {Box} from "@mui/material";
import "../../css/main.css";


function MainContainer(){
    const maxtWidth=window.innerWeight+'px';

    return(
        <Box className='bodyArea' style={{width:maxtWidth, minHeight:'839px' }}>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/products" element={<Products/>} />
                <Route path="/support" element={<Support />} />

                <Route exact path="/support/faq" element={<FaqDetail />}/>
                <Route exact path="/products/groupware" element={<GroupWare/>}/>
                <Route exact path="/products/privacyscan" element={<PrivacyScan/>}/>
                <Route exact path="/products/encryption" element={<Encryption/>}/>
                <Route exact path="/products/authentication" element={<Authentication/>}/>
                <Route exact path="/products/vfinder" element={<VFinder/>}/>
                <Route exact path="/products/firewall" element={<Firewall/>}/>
                <Route exact path="/products/siem" element={<Siem/>}/>
                <Route exact path="/products/kms" element={<KMS/>}/>
                <Route exact path="/products/safem" element={<SafeM/>}/>
                <Route exact path="/products/sparrow" element={<Sparrow/>}/>
                <Route exact path="/products/cybellum" element={<Cybellum/>}/>
                <Route exact path="/products/grippin" element={<Grippin/>}/>
            </Routes>
        </Box>
    );
}

export default MainContainer;
