import React from "react";
import "../../css/info.css";
import cybellum from "../../image/cybellum.jpg";
import SideBar from "../../main/SideBar";
import {useTranslation} from "react-i18next";

function Cybellum(){
    const {t} = useTranslation();

    return(
        <>
            <SideBar />
            <br/>
            <div className='commandArea'>
                <div className='detailTitle'>{t('CYBELLUM_TITLE')}</div>
                <div className='detailData'>{t('CYBELLUM_DATA')}</div>
                <img src={cybellum} className={'detailImage'}/>

                <div className='datailCategory'>{t('DETAIL_CATEGORY_FUNCTION')}</div>
                <div className={'detailSubTitle'}>{t('CYBELLUM_FUNC_A')}</div>
                <div className={'detailData'}>
                    <div>{t('CYBELLUM_FUNC_A001')}</div>
                    <div>{t('CYBELLUM_FUNC_A002')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('CYBELLUM_FUNC_B')}</div>
                <div className={'detailData'}>{t('CYBELLUM_FUNC_B001')}</div>

                <div className={'detailSubTitle'}>{t('CYBELLUM_FUNC_C')}</div>
                <div className={'detailData'}>{t('CYBELLUM_FUNC_C001')}</div>

                <div className={'detailSubTitle'}>{t('GROUPWARE_FUNC_D')}</div>
                <div className={'detailData'}>
                    <div>{t('GROUPWARE_FUNC_D001')}</div>
                    <div>{t('GROUPWARE_FUNC_D002')}</div>
                    <div>{t('GROUPWARE_FUNC_D003')}</div>
                </div>

                <br />
                <div className='datailCategory'>{t('DETAIL_CATEGORY_FETURE')}</div>
                <div className={'detailSubTitle'}>{t('CYBELLUM_FETURE_A')}</div>
                <div className={'detailData'}>{t('CYBELLUM_FETURE_A001')}</div>

                <div className={'detailSubTitle'}>{t('CYBELLUM_FETURE_B')}</div>
                <div className={'detailData'}>{t('CYBELLUM_FETURE_B001')}</div>

                <div className='detailSubTitle'>{t('CYBELLUM_FETURE_C')}</div>
                <div className='detailData'>
                    <div>{t('CYBELLUM_FETURE_C001')}</div>
                    <div>{t('CYBELLUM_FETURE_C002')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('CYBELLUM_FETURE_D')}</div>
                <div className={'detailData'}>{t('CYBELLUM_FETURE_D001')}</div>

                <div className={'detailSubTitle'}>{t('CYBELLUM_FETURE_E')}</div>
                <div className={'detailData'}>{t('CYBELLUM_FETURE_E001')}</div>
            </div>
        </>
    );
}

export default Cybellum;
