import React from "react";
import "../../css/info.css";
import SideBar from "../../main/SideBar";
import msaber_ko from "../../image/authentication.png";
import msaber_en from "../../image/authentication_en.png"
import {useTranslation} from "react-i18next";
import i18next from "i18next";

function Authentication(){
    const {t} = useTranslation();

    return(
        <>
            <SideBar/>
            <br/>
            <div className='commandArea'>
                <div className='detailTitle'>{t('AUTHEN_TITLE')}</div>
                <div className='detailData'>{t('AUTHEN_DATA')}</div>
                {i18next.language==='kr'? <img src={msaber_ko} className={'detailImage'}/> :
                    <img src={msaber_en} className={'detailImage'}/>}

                <div className='datailCategory'>{t('DETAIL_CATEGORY_FUNCTION')}</div>
                <div className='detailSubTitle'>{t('AUTHEN_FUNC_A')}</div>
                <div className='detailData'>
                    <div>{t('AUTHEN_FUNC_A001')}</div>
                    <div>{t('AUTHEN_FUNC_A002')}</div>
                    <div>{t('AUTHEN_FUNC_A003')}</div>
                    <div>{t('AUTHEN_FUNC_A004')}</div>
                </div>

                <div className='detailSubTitle'>{t('AUTHEN_FUNC_B')}</div>
                <div className='detailData'>
                    <div>{t('AUTHEN_FUNC_B001')}</div>
                    <div>{t('AUTHEN_FUNC_B002')}</div>
                </div>

                <div className='detailSubTitle'>{t('AUTHEN_FUNC_D')}</div>
                <div className='detailData'>
                    <div>{t('AUTHEN_FUNC_D001')}</div>
                    <div>{t('AUTHEN_FUNC_D002')}</div>
                </div>

                <br />
                <div className='datailCategory'>{t('DETAIL_CATEGORY_FETURE')}</div>
                <div className='detailSubTitle'>{t('AUTHEN_FETURE_A')}</div>
                <div className='detailData'>{t('AUTEHEN_FETURE_A001')}</div>

                <div className='detailSubTitle'>{t('AUTHEN_FETURE_B')}</div>
                <div className='detailData'>{t('AUTEHEN_FETURE_B001')}</div>


                <div className='detailSubTitle'>{t('AUTHEN_FETURE_C')}</div>
                <div className='detailData'>{t('AUTEHEN_FETURE_C001')}</div>

            </div>
        </>
    );
}

export default Authentication;
