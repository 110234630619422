import React, {useState, useEffect} from "react";
import "../css/faq.css";
//import FaqList from "./FaqList";
import Faq from "./Faq";
import i18next from "i18next";
import AmoaDataList from "./SupportData/AmoaDataList"
import {Card,Grid} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SideBar from "../main/SideBar";

function FaqDetail(){
    const langData= AmoaDataList();
    const [lang, setLang]=useState(i18next.language);
    const [data, setData]=useState(langData);
    const [open, setOpen]=useState(false);

    const onToggle=(id)=>{
        setData(
            data.map(item =>
                item.id === id ? { ...item, open: !item.open } : item
            )
        );
    };

    const handleClick = () =>{
        setOpen(!open)
    }

    if (i18next.language !== lang) {
        setLang(i18next.language);
        setData(langData);
    };

    useEffect(()=>{
        console.log(i18next.language)
    },[lang])

    return(
        <>
            <SideBar />
            <div className={'commandArea faqDetailCss'}>
               {/*<FaqList data={data} onToggle={onToggle} />*/}
               {/* <Faq />*/}
            </div>
        </>
    );
}


export default FaqDetail;
