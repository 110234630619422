import React from "react";
import "../../css/info.css";
import kms_kr from "../../image/kms_kr.png";
import kms_en from "../../image/kms_en.png";
import SideBar from "../../main/SideBar";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

function PrivacyScan(){
    const {t} = useTranslation();

    return(
        <>
            <SideBar/>
            <br/>
            <div className='commandArea'>
                <div className='detailTitle'>{t('KMS_TITLE')}</div>
                <div className='detailData'>{t('KMS_DATA')}</div>
                {i18next.language==='kr'?<img src={kms_kr} className={'detailImage'}/> :
                    <img src={kms_en} className={'detailImage'}/>}


                <div className='datailCategory'>{t('DETAIL_CATEGORY_FETURE')}</div>
                <div className={'detailSubTitle'}>{t('KMS_FETURE_A')}</div>
                <div className={'detailData'}>
                    <div>{t('KMS_FETURE_A001')}</div>
                    <div>{t('KMS_FETURE_A002')}</div>
                    <div>{t('KMS_FETURE_A003')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('KMS_FETURE_B')}</div>
                <div className={'detailData'}>
                    <div>{t('KMS_FETURE_B001')}</div>
                    <div>{t('KMS_FETURE_B002')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('KMS_FETURE_C')}</div>
                <div className={'detailData'}>
                    <div>{t('KMS_FETURE_C001')}</div>
                    <div>{t('KMS_FETURE_C002')}</div>
                    <div>{t('KMS_FETURE_C003')}</div>
                </div>
            </div>
        </>
    );
}

export default PrivacyScan;
