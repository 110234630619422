import React from "react";
import logo from "../image/키스톤 시큐리티 로고.png"
import "../css/main.css";
import {useTranslation} from "react-i18next";
import mainImage from "../image/main3.png"
import {Grid} from '@mui/material';

function MainPage(){
    const {t} = useTranslation();

    return(
        <Grid className={'contentArea'}>
            <Grid className='mainArea'>
                <img src={logo} style={{width:'350px', float:'left'}}/>
                <div className="mainData">
                    <br />
                    {t('MAIN_DATA')}
                </div>
            </Grid>
            <div>
                <img src={mainImage} className={'mainImage'}/>
            </div>
        </Grid>
    );
}

export default MainPage;
