import React from "react";
import ProductSummary from "./ProductSummary";
import SideBar from "../main/SideBar";

function Products(){
    return(
        <>
            <SideBar />
            <ProductSummary/>
        </>
    );
}

export default Products;
