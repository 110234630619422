import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import i18n from "../src/lang/i18n";
import {I18nextProvider} from "react-i18next";

window.onload = () => {
    console.log('language :: ', window.navigator.language)
    render()
}

function render() {
    ReactDOM.render(
        <React.StrictMode>
            <BrowserRouter>
                <I18nextProvider i18n={
                    localStorage.getItem('lang')===null?
                        i18n(window.navigator.language):i18n(localStorage.getItem('lang'))}> {/*윈도우에 설정된 언어로 다중언어 지정*/}
                    <App/>
                </I18nextProvider>
            </BrowserRouter>
        </React.StrictMode>,
        document.getElementById('root')
    );
}
