import React from "react";
import "../../css/info.css";
import SideBar from "../../main/SideBar";
import sparrow_ko from "../../image/sparrow_ko.png";
import sparrow_en from "../../image/sparrow_en.png";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

function Sparrow(){
    const {t} = useTranslation();

    return(
        <>
            <SideBar/>
            <br/>
            <div className='commandArea'>
                <div className='detailTitle'>{t('SPARROW_TITLE')}</div>
                <div className='detailData'>{t('SPARROW_DATA')}</div>
                {i18next.language==='kr'? <img src={sparrow_ko} className={'detailImage'}/> :
                    <img src={sparrow_en} className={'detailImage'}/>}

                <div className='datailCategory'>{t('DETAIL_CATEGORY_FUNCTION')}</div>
                <div className='detailSubTitle'>{t('SPARROW_FUNC_A')}</div>
                <div className='detailData'>
                    <div>{t('SPARROW_FUNC_A001')}</div>
                    <div>{t('SPARROW_FUNC_A002')}</div>
                </div>

                <div className='detailSubTitle'>{t('SPARROW_FUNC_B')}</div>
                <div className='detailData'>
                    <div>{t('SPARROW_FUNC_B001')}</div>
                    <div>{t('SPARROW_FUNC_B002')}</div>
                    <div>{t('SPARROW_FUNC_B003')}</div>
                </div>

                <div className='detailSubTitle'>{t('SPARROW_FUNC_C')}</div>
                <div className='detailData'>
                    <div>{t('SPARROW_FUNC_C001')}</div>
                    <div>{t('SPARROW_FUNC_C002')}</div>
                </div>


                <br />
                <div className='datailCategory'>{t('DETAIL_CATEGORY_FETURE')}</div>
                <div className='detailSubTitle'>{t('SPARROW_FETURE_A')}</div>
                <div className='detailData'>
                    <div> {t('SPARROW_FETURE_A001')}</div>
                    <div> {t('SPARROW_FETURE_A002')}</div>
                </div>

                <div className='detailSubTitle'>{t('SPARROW_FETURE_B')}</div>
                <div className='detailData'>
                    <div>{t('SPARROW_FETURE_B001')}</div>
                    <div>{t('SPARROW_FETURE_B002')}</div>
                </div>


                <div className='detailSubTitle'>{t('SPARROW_FETURE_C')}</div>
                <div className='detailData'>{t('SPARROW_FETURE_C001')}</div>

            </div>
        </>
    );
}

export default Sparrow;
