import React from "react";
import "../../css/info.css";
import SideBar from "../../main/SideBar";
import firewall_ko from "../../image/firewall_kr.png";
import firewall_en from "../../image/firewall_en.png";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

function Firewall(){
    const {t} = useTranslation();

    return(
        <>
            <SideBar />
            <br/>
            <div className='commandArea'>
                <div className='detailTitle'>{t('FIREWALL_TITLE')}</div>
                <div className='detailData'>{t('FIREWALL_DATA')}</div>
                {i18next.language==='kr'?<img src={firewall_ko} className={'detailImage'}/> :
                    <img src={firewall_en} className={'detailImage'}/>}

                <div className='datailCategory'>{t('DETAIL_CATEGORY_FUNCTION')}</div>
                <div className={'detailSubTitle'}>{t('FIREWALL_FUNC_A')}</div>
                <div className={'detailData'}>
                    <div>{t('FIREWALL_FUNC_A001')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('FIREWALL_FUNC_B')}</div>
                <div className={'detailData'}>
                    <div>{t('FIREWALL_FUNC_B001')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('FIREWALL_FUNC_C')}</div>
                <div className={'detailData'}>
                    <div>{t('FIREWALL_FUNC_C001')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('FIREWALL_FUNC_D')}</div>
                <div className={'detailData'}>
                    <div>{t('FIREWALL_FUNC_D001')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('FIREWALL_FUNC_E')}</div>
                <div className={'detailData'}>
                    <div>{t('FIREWALL_FUNC_E001')}</div>
                </div>

                <br />
                <div className='datailCategory'>{t('DETAIL_CATEGORY_FETURE')}</div>
                <div className={'detailSubTitle'}>{t('FIREWALL_FETURE_A')}</div>
                <div className={'detailData'}>
                    <div>{t('FIREWALL_FETURE_A001')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('FIREWALL_FETURE_B')}</div>
                <div className={'detailData'}>
                    <div>{t('FIREWALL_FETURE_B001')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('FIREWALL_FETURE_C')}</div>
                <div className={'detailData'}>
                    <div>{t('FIREWALL_FETURE_C001')}</div>
                </div>
            </div>
        </>
    );
}

export default Firewall;
