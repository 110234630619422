import React from "react";
import {useNavigate} from "react-router-dom";
import '../css/info.css'
import {useTranslation} from "react-i18next";
import {Box} from '@mui/material';

function ProductSummary(){
    const navigate=useNavigate();
    // const numHeight = Number(window.innerHeight) - 70 - 28;
    // const chartHeight = String(numHeight)+'px';
    // console.log(chartHeight)
    const {t} = useTranslation();

    return(
        <>
            <br/>
            <Box className='commandArea'>
                <div className='infoSubTitle'
                     onClick={() => navigate('/products/groupware')}>{t('PRODUCT_TITLE_GROUPWARE')}</div>
                <div className='infoData'>{t('PRODUCT_DATA_GROUPWARE')}</div>

                <div className='infoSubTitle'
                     onClick={() => navigate('/products/privacyscan')}>{t('PRODUCT_TITLE_PRIVACYSCAN')}</div>
                <div className='infoData'>{t('PRODUCT_DATA_PRIVACYSCAN')}</div>

                <div className='infoSubTitle'
                     onClick={() => navigate('/products/encryption')}>{t('PRODUCT_TITLE_ENCRYPTION')}</div>
                <div className='infoData'>{t('PRODUCT_DATA_ENCRYPTION')}</div>

                <div className='infoSubTitle'
                     onClick={() => navigate('/products/kms')}>{t('PRODUCT_TITLE_KMS')}</div>
                <div className='infoData'>{t('PRODUCT_DATA_KMS')}</div>

                <div className='infoSubTitle'
                     onClick={() => navigate('/products/authentication')}>{t('PRODUCT_TITLE_AUTHENTICATION')}</div>
                <div className='infoData'>{t('PRODUCT_DATA_AUTHENTICATION')}</div>

                <div className='infoSubTitle'
                     onClick={() => navigate('/products/vfinder')}>{t('PRODUCT_TITLE_VFINDER')}</div>
                <div className='infoData'>{t('PRODUCT_DATA_VFINDER')}</div>

                <div className='infoSubTitle'
                     onClick={() => navigate('/products/safem')}>{t('PRODUCT_TITLE_SAFEM')}</div>
                <div className='infoData'>{t('PRODUCT_DATA_SAFEM')}</div>

                {/*<div className='infoSubTitle'*/}
                {/*     onClick={() => navigate('/products/cybellum')}>{t('PRODUCT_TITLE_GRIPPIN')}</div>*/}
                {/*<div className='infoData'>{t('PRODUCT_DATA_GRIPPIN')}</div>*/}

                <div className='infoSubTitle'
                     onClick={() => navigate('/products/cybellum')}>{t('PRODUCT_TITLE_CYBELLUM')}</div>
                <div className='infoData'>{t('PRODUCT_DATA_CYBELLUM')}</div>

                <div className='infoSubTitle'
                     onClick={() => navigate('/products/sparrow')}>{t('PRODUCT_TITLE_SPARROW')}</div>
                <div className='infoData'>{t('PRODUCT_DATA_SPARROW')}</div>

                <div className='infoSubTitle'
                     onClick={() => navigate('/products/firewall')}>{t('PRODUCT_TITLE_FIREWALL')}</div>
                <div className='infoData'>{t('PRODUCT_DATA_FIREWALL')}</div>

                <div className='infoSubTitle' onClick={() => navigate('/products/siem')}>{t('PRODUCT_TITLE_SIEM')}</div>
                <div className='infoData'>{t('PRODUCT_DATA_SIEM')}</div>
            </Box>

        </>
    );
}

export default ProductSummary;
