import React from "react";
import "../../css/info.css";
import privacy_kr from "../../image/privacy_kr.png";
import privacy_en from "../../image/privacyscan_en.png";
import SideBar from "../../main/SideBar";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

function PrivacyScan(){
    const {t} = useTranslation();

    return(
        <>
            <SideBar/>
            <br/>
            <div className='commandArea'>
                <div className='detailTitle'>{t('PRIVACY_TITLE')}</div>
                <div className='detailData'>{t('PRIVACY_DATA')}</div>
                {i18next.language==='kr'?<img src={privacy_kr} className={'detailImage'}/> :
                    <img src={privacy_en} className={'detailImage'}/>}

                <div className='datailCategory'>{t('DETAIL_CATEGORY_FUNCTION')}</div>
                <div className={'detailSubTitle'}>{t('PRIVACY_FUNC_A')}</div>
                <div className={'detailData'}>
                    <div>{t('PRIVACY_FUNC_A001')}</div>
                    <div>{t('PRIVACY_FUNC_A002')}</div>
                    <div>{t('PRIVACY_FUNC_A003')}</div>
                    <div>{t('PRIVACY_FUNC_A004')}</div>
                </div>

                <div className={'detailSubTitle'}>{t('PRIVACY_FUNC_B')}</div>
                <div className={'detailData'}>
                    <div>{t('PRIVACY_FUNC_B001')}</div>
                    <div>{t('PRIVACY_FUNC_B002')}</div>
                    <div>{t('PRIVACY_FUNC_B003')}</div>
                    <div>{t('PRIVACY_FUNC_B004')}</div>
                    <div>{t('PRIVACY_FUNC_B005')}</div>
                </div>
                <br />
                <div className='datailCategory'>{t('DETAIL_CATEGORY_FETURE')}</div>
                <div className={'detailSubTitle'}>{t('PRIVACY_FETURE_A')}</div>
                <div className={'detailData'}>{t('PRIVACY_FETURE_A001')}</div>

                <div className={'detailSubTitle'}>{t('PRIVACY_FETURE_B')}</div>
                <div className={'detailData'}>
                    <div>{t('PRIVACY_FETURE_B001')}</div>
                    <div>{t('PRIVACY_FETURE_B002')}</div>
                    <div>{t('PRIVACY_FETURE_B003')}</div>
                </div>
            </div>
        </>
    );
}

export default PrivacyScan;
